'use client';
import * as React from 'react';
import {
  TCultureCodes,
  TSupportedCurrencies,
  formatCurrency,
  formatDecimal,
  formatPercentage,
  parseSupportedCurrencyFromCalculationCountry,
} from '@dreamplan/utils';
import { useLocale } from '../../utils/useLocale';

type Props = React.ComponentProps<'p'>;

/**
 * DEPRECATED: please use `Text` instead, eg. <Text asHTML>{content}</Text>
 */
export const Paragraph = React.forwardRef(
  (paraProps: Props & { content: string }, ref: React.Ref<HTMLParagraphElement>) => {
    const { content, ...props } = paraProps;

    const newLineEscapedContent = content?.replace(/\n/g, '<br />');

    return <p ref={ref} {...props} dangerouslySetInnerHTML={{ __html: newLineEscapedContent }} />;
  },
);

Paragraph.displayName = 'Paragraph';

type FormatCurrencyProps = {
  amount?: number;
  cultureCode?: TCultureCodes;
  currency?: TSupportedCurrencies;
  maxFractionDigits?: number;
  minFractionDigits?: number;
};

export const FormatCurrency = React.forwardRef(
  (props: Props & FormatCurrencyProps, ref: React.Ref<HTMLSpanElement>) => {
    const { amount, cultureCode, currency, maxFractionDigits, minFractionDigits, ...spanProps } =
      props;
    if (!amount) {
      return <span>-</span>;
    }
    const locale = useLocale();
    const currencyCode = parseSupportedCurrencyFromCalculationCountry('DK'); // until we support mutliple-currencies
    const formattedAmount = formatCurrency(
      amount,
      cultureCode ?? locale,
      currency ?? currencyCode,
      maxFractionDigits,
      minFractionDigits,
    );

    return (
      <span ref={ref} {...spanProps}>
        {formattedAmount}
      </span>
    );
  },
);

FormatCurrency.displayName = 'FormatCurrency';

type FormatDecimalProps = {
  value: number;
  cultureCode?: TCultureCodes;
  maxFractionDigits?: number;
  minFractionDigits?: number;
};

export const FormatDecimal = React.forwardRef(
  (props: Props & FormatDecimalProps, ref: React.Ref<HTMLSpanElement>) => {
    const { value, cultureCode, maxFractionDigits, minFractionDigits, ...spanProps } = props;
    const locale = useLocale();
    const formattedDecimal = formatDecimal(
      value,
      cultureCode ?? locale,
      maxFractionDigits,
      minFractionDigits,
    );

    return (
      <span ref={ref} {...spanProps}>
        {formattedDecimal}
      </span>
    );
  },
);

FormatDecimal.displayName = 'FormatDecimal';

type FormatPercentageProps = {
  value: number;
  cultureCode?: TCultureCodes;
  maxFractionDigits?: number;
  minFractionDigits?: number;
};

export const FormatPercentage = React.forwardRef(
  (props: Props & FormatPercentageProps, ref: React.Ref<HTMLSpanElement>) => {
    const { value, cultureCode, maxFractionDigits, minFractionDigits, ...spanProps } = props;

    const locale = useLocale();
    const formattedPercentage = formatPercentage(
      value,
      cultureCode ?? locale,
      maxFractionDigits,
      minFractionDigits,
    );

    return (
      <span ref={ref} {...spanProps}>
        {formattedPercentage}
      </span>
    );
  },
);

FormatPercentage.displayName = 'FormatPercentage';
